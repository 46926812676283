<script setup lang="ts">
  import { computed, nextTick, ref, watch } from 'vue'
  import { getComputedStyle } from '@/common/utils'

  const emit = defineEmits(['open'])
  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      default: ''
    },
    display: {
      type: String,
      default: 'default',
    },
  })

  const host = ref<HTMLElement | null>(null)
  const menu = ref<HTMLElement | null>(null)
  const origin = ref<HTMLElement | null>(null)

  const defaultWidth = 280
  const offsetX = ref(0)
  const offsetY = ref(0)
  const visible = ref(false)

  const menuHostStyles = computed(() => {
    const styles: Record<string, string> = {
      left: offsetX.value + 'px',
      top: offsetY.value + 'px',
    }
    if (props.maxWidth) {
      styles.width = props.maxWidth + 'px'
    }
    return styles
  })

  function onClick (e: MouseEvent) {
    let useOrigin: HTMLElement = origin.value as HTMLElement
    if (!useOrigin) {
      const target = e.currentTarget as HTMLElement
      if (target.classList.contains('js-position-origin')) {
        useOrigin = target
      } else {
        const origin = (e.currentTarget as HTMLElement)?.querySelector<HTMLElement>('.js-position-origin')
        if (!origin) {
          return
        }
        useOrigin = origin
      }
    }

    const offset = useOrigin.getBoundingClientRect()
    const pullLeft = 67

    visible.value = true

    offsetX.value = (offset.left + offset.width) - (defaultWidth - pullLeft)
    offsetY.value = offset.top + 10

    // Fix the positioning if the menu does not have the default width.
    // We can only do this after the menu has been rendered.
    nextTick(() => {
      if (menu.value && host.value) {
        const padding = parseInt(getComputedStyle(host.value as HTMLElement, 'padding-right'))
        const width = host.value.clientWidth
        offsetX.value = (offset.left + offset.width) - width + padding
      }
    })
  }

  watch(() => visible.value, (value) => {
    emit('open', value)
  })
</script>

<template>
  <div>
    <slot name="button" :onClick="onClick" :isOpen="visible">
      <div
        ref="origin"
        class="js-position-origin button p-2 user-select-none leading-none cursor-pointer flex items-center justify-center rounded overflow-hidden hover:bg-gray-100"
        :class="{
          'bg-gray-100' : visible && display === 'default',
          'bg-white bg-opacity-10' : visible && display === 'inverted',
          'hover:bg-white hover:bg-opacity-10' : display === 'inverted',
          'hover:bg-gray-100': display === 'default',
          'opacity-50 pointer-events-none': disabled,
          'border border-gray-300 label' : label,
        }"
        @click="onClick"
      >
        <div v-if="label" class="text-sm mr-4 text-gray-600 pointer-cursor">
          {{ $t(label) }}
        </div>
        <svg
          class="h-4 fill-current"
          :class="`${display === 'inverted' ? 'text-white' : 'text-gray-900'}`"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <circle cx="256" cy="256" r="64" />
          <circle cx="256" cy="448" r="64" />
          <circle cx="256" cy="64" r="64" />
        </svg>
      </div>
    </slot>
    <teleport to="body">
      <transition
        enterActiveClass="transition ease-out duration-100"
        enterFromClass="opacity-0 scale-95"
        enterToClass="opacity-100 scale-100"
        leaveActiveClass="transition ease-in duration-75"
        leaveFromClass="opacity-100 scale-100"
        leaveToClass="opacity-0 scale-95"
      >
        <div v-show="visible && !disabled" class="fixed inset-0 z-50 origin-top-right" @click="visible = false">
          <div ref="host" class="fixed menu-host" :style="menuHostStyles">
            <div ref="menu" class="menu bg-white shadow-xl rounded-md text-sm">
              <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<style lang="stylus" scoped>
  .button
    padding .6rem
    transition .1s ease-out background-color

    &.label
      padding .6rem .6rem .6rem 1.2rem

  .menu-host
    min-width 280px
    padding 2rem
</style>
