export type RuntimeEnvironment = 'production' | 'development' | 'testing' | 'unknown'
export const Config = Symbol('config')

export interface ApplicationConfig {
  environment: RuntimeEnvironment;
  backend: string;
  version: string;
}

const EnvProduction: RuntimeEnvironment = 'production'
const EnvDevelopment: RuntimeEnvironment = 'development'

const buildConfig = (): ApplicationConfig => {
  const values: ApplicationConfig = {
    environment: import.meta.env.PROD ? EnvProduction : EnvDevelopment,
    backend: '',
    version: '5.342',
  }

  let backend = `${window.location.protocol}//${window.location.host}/backend`
  if (values.environment === EnvDevelopment) {
    backend = 'http://localhost:8888/backend'
  }
  values.backend = backend

  return values
}

function isDemo () {
  return document.location.host === 'demo.5.caresuite.ch' || document.location.host.includes('.demo.caresuite.ch')
}

const config = buildConfig()

export { config, isDemo }
