<script setup lang="ts">
  import { computed } from 'vue'
  import { useStore } from '@/store'
  import MainFooter from '@/common/layouts/main/MainFooter.vue'
  import MainHeader from '@/common/layouts/main/MainHeader.vue'
  import ProblemCallout from '@/common/components/system/ProblemCallout.vue'

  const store = useStore()
  const isFullscreen = computed(() => store.state.app.isFullscreen)

  const systemContext = computed(() => store.state.app.systemContext ?? 1)
</script>

<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-start">
    <ProblemCallout />
    <MainHeader v-if="!isFullscreen" />
    <main class="flex-1" :class="{'pull-up' : !isFullscreen}">
      <RouterView :key="systemContext" />
    </main>
    <MainFooter v-if="!isFullscreen" />
  </div>
</template>

<style scoped>
  .pull-up {
    margin-top: -13.15rem;
  }
</style>