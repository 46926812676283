<script setup lang="ts">
  import MdiIcon from '@/common/components/svg/MdiIcon.vue'
  import { mdiAlertCircleOutline, mdiClose } from '@mdi/js'
  import { computed, ref, watchEffect } from 'vue'
  import { useStore } from '@/store'
  import { useRoute } from 'vue-router'
  import { useSessionStorage } from '@vueuse/core'

  const visible = ref(false)
  const store = useStore()
  const route = useRoute()

  watchEffect(() => {
    if (!store.state.app.features.demo_mode && store.state.app.outdatedHubs.length > 0) {
      visible.value = true
    }
  })

  const dismissed = useSessionStorage('cs.dismissed.problems.callout', false)

  const loggedIn = computed(() => store.getters['user/isLoggedIn'])
</script>

<template>
  <div v-if="visible && loggedIn && !dismissed" class="bg-orange-200 border-b border-orange-200 text-xs py-3 text-orange-700">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between">
      <div class="flex items-center justify-start">
        <div class="flex-grow-0 w-8 mr-4">
          <MdiIcon :icon="mdiAlertCircleOutline" class="size-7" />
        </div>
        <div class="leading-tight">
          <p class="mb-1">
            <strong class="font-semibold">{{ $t('hub.callouts.outdated_data.title') }}</strong><br>
          </p>

          <i18n-t tag="p" keypath="hub.callouts.outdated_data.text">
            <RouterLink v-if="route.name !== 'hubList'" v-can="'admin::manage_system'" class="underline whitespace-nowrap" :to="{ name: 'hubList' }">
              {{ $t('hub.callouts.outdated_data.action') }} &rarr;
            </RouterLink>
          </i18n-t>
        </div>
      </div>
      <button class="appearance-none cursor-pointer p-1" @click="visible = false; dismissed = true">
        <MdiIcon :icon="mdiClose" class="size-5" />
      </button>
    </div>
  </div>
</template>