<script setup lang="ts">
  import { computed, nextTick, onMounted, ref, watch } from 'vue'
  import { EntityReference, SearchQuery, SearchQueryVariables } from '@/common/graphql/types'
  import Popup from '@/common/components/popup/Popup.vue'
  import { useRouter } from 'vue-router'
  import { useQuery } from '@vue/apollo-composable'
  import QuerySearch from '@/common/components/search/graphql/QuerySearch.gql'
  import { throttle } from '@/common/utils'
  import SearchPolling from '@/common/components/search/SearchPolling.vue'
  import { SearchIcon } from '@heroicons/vue/solid'
  import SearchResultItem from '@/common/components/search/SearchResultItem.vue'
  import BatteryIndicator from '@/modules/device/components/BatteryIndicator.vue'
  import SystemTreeIconNotReachable from '@/modules/device/components/systemtreeicon/SystemTreeIconNotReachable.vue'
  import Loader from '@/common/components/loader/Loader.vue'

  const emit = defineEmits(['close'])

  interface EntityReferenceWithPosition extends EntityReference {
    position: number;
  }

  const router = useRouter()
  const query = ref('')
  const searchInput = ref<HTMLElement | null>(null)
  const selectedPosition = ref(-1)

  // Focus the search input when the popup opens.
  onMounted(() => {
    nextTick(() => {
      searchInput.value?.focus()
    })
  })

  // Search Query.
  const {
    result: queryResult,
    refetch,
    loading,
    onResult
  } = useQuery<SearchQuery, SearchQueryVariables>(QuerySearch, () => ({ query: query.value.trim() }), () => ({
    fetchPolicy: 'no-cache',
    enabled: query.value.trim().length > 1,
  }))

  onResult(() => {
    selectedPosition.value = -1
  })

  // The search results are grouped by category. This "results" computed property adds a
  // "position" parameter to each result. This is used to select the entries using the keyboard.
  let position = 0
  const results = computed(() => {
    position = 0
    if (!queryResult.value) {
      return []
    }

    return queryResult.value.search.map(category => ({
      ...category,
      results: (category.results ?? []).map(result => ({ ...result, position: position++ }) as EntityReferenceWithPosition)
    }))
  })

  // Throttle the search query to avoid too many requests when typing.
  const throttledSearch = throttle(async () => {
    await refetch({ query: query.value })
  }, 500)

  // Show a loading spinner after a short delay.
  const delayedLoading = ref(false)
  let loadingDelay: number | undefined

  watch(() => loading.value, (value) => {
    if (value) {
      loadingDelay = window.setTimeout(() => delayedLoading.value = true, 200)
    } else {
      clearTimeout(loadingDelay)
      delayedLoading.value = false
    }
  })

  // Trigger the search query when the user types.
  async function onSearch () {
    if (query.value.length <= 1) {
      return
    }

    throttledSearch()
  }

  // Keyboard navigation.
  function selectNext () {
    if (selectedPosition.value >= position - 1) {
      selectedPosition.value = 0
    } else {
      selectedPosition.value++
    }
  }

  // Keyboard navigation.
  function selectPrev () {
    if (selectedPosition.value <= 0) {
      selectedPosition.value = position - 1
    } else {
      selectedPosition.value--
    }
  }

  // Generate the link target for different search result kinds.
  function getLinkTarget (item: EntityReference) {
    const name = item.kind === 'flightprofile' ? 'flightProfileForm' : 'systemTreeNodeForm'
    return { name, params: { id: item.entity.id } }
  }

  // open the selected result when the enter key is pressed.
  function openSelectedResult () {
    if (results.value.length === 0) {
      return
    }

    // Open the first result if none is selected.
    if (selectedPosition.value === -1) {
      selectedPosition.value = 0
    }

    if (!selectedResult.value) {
      return
    }

    const target = getLinkTarget(selectedResult.value)
    if (target) {
      router.push(target)
    }

    close()
  }

  // Bring selected result into view when it changes.
  watch(() => selectedPosition.value, () => {
    const selectedElement = document.querySelector(`[data-position="${selectedPosition.value}"]`) as HTMLElement | null
    if (selectedElement) {
      selectedElement.scrollIntoView({ block: 'nearest' })
    }
  })

  // Get the selected result from the selection position.
  const selectedResult = computed(() => {
    if (selectedPosition.value === -1) {
      return null
    }

    return results.value.flatMap(category => category.results).find(result => result.position === selectedPosition.value)
  })

  function close () {
    emit('close')
  }
</script>

<template>
  <Popup
    innerClasses="bg-gray-50 rounded overflow-hidden"
    maxWidth="700px"
    @close="close"
  >
    <div data-testid="global-search-results">
      <div>
        <div class="px-3 pt-3">
          <div ref="search" class="relative text-gray-400 focus-within:text-gray-600">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <SearchIcon class="h-5 w-5" aria-hidden="true" />
            </div>
            <transition name="fade">
              <div v-if="delayedLoading" class="pointer-events-none absolute inset-y-0 right-0 pr-3 flex items-center">
                <Loader :size="20" />
              </div>
            </transition>
            <input
              ref="searchInput"
              v-model="query"
              data-testid="global-search"
              autocomplete="off"
              class="block pl-10 w-full border-gray-400 rounded-md focus:border-csBlue-300 focus:ring focus:ring-csBlue-300 focus:ring-opacity-50 sm:text-sm sm:leading-5 text-base"
              :placeholder="`${$t('common.search')}`"
              type="search"
              @input="onSearch"
              @keydown.esc="close"
              @keydown.enter="openSelectedResult"
              @keydown.down.prevent="selectNext"
              @keydown.up.prevent="selectPrev"
            >
          </div>
        </div>

        <div class="min-h-72" :class="{'opacity-50': delayedLoading, 'flex items-center justify-center': results.length === 0 }">
          <template v-if="results.length > 0">
            <div class="search-result px-3 pb-4">
              <div v-for="result in results" :key="result.category" class="pb-2 pt-4">
                <div class="text-gray-600 text-xs font-semibold mb-1.5">
                  <template v-if="result.category === 'flightprofile'">
                    {{ $t('flightprofile.singular') }}
                  </template>
                  <template v-else>
                    {{ $t(result.category) }}
                  </template>
                </div>
                <div class="space-y-1">
                  <SearchResultItem
                    v-for="item in result.results"
                    :key="item.key"
                    :class="{'is-selected': selectedPosition === item.position}"
                    :data-position="item.position"
                    :query="query"
                    :name="item.entity.name"
                    :textLong="item.entity.text_long"
                    :textShort="item.entity.text_short"
                    :serial="item.entity.serial"
                    :mac="item.entity.mac"
                    :battery="item.entity.battery"
                    :reachable="item.entity.reachable"
                    :note="item.entity.note"
                    :section="item.entity.section"
                    :kind="item.kind === 'flightprofile' ? 'flightprofile' : item.entity.kind"
                    :radio="item.entity.radio"
                    :linkTarget="getLinkTarget(item)"
                    @click="close"
                  >
                    <div class="flex justify-end w-16 relative">
                      <template v-if="item.entity.radio">
                        <BatteryIndicator
                          v-if="item.entity.reachable"
                          :percentage="item.entity.battery"
                        />
                        <SystemTreeIconNotReachable v-else />
                      </template>
                    </div>
                  </SearchResultItem>
                </div>
              </div>
            </div>
          </template>

          <div v-else class="py-12 px-8 text-center text-gray-500">
            <p v-if="query.length > 0" class="mb-2">
              <strong>{{ $t('common.global_search.no_hits.title') }}</strong>
            </p>
            <p>{{ $t('common.global_search.no_hits.text') }}</p>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-t-md relative z-10">
        <SearchPolling @close="close" />
      </div>
    </div>
  </Popup>
</template>

<style lang="stylus">
.search-result
  max-height theme('spacing.72')
  overflow-y auto
  scrollbar-color #969faf #f5f6f7
  scrollbar-width thin
</style>
