<script lang="ts" setup>
  import { computed } from 'vue'
  import { useStore } from '@/store'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { NotifyInput } from '@/store/notification.state'
  import { config } from '@/bootstrap/config'
  import { logError } from '../../utils'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import Search from '@/common/components/search/SearchBox.vue'

  // @ts-ignore
  import { ExclamationIcon, LockClosedIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
  // @ts-ignore
  import { SwitchHorizontalIcon } from '@heroicons/vue/solid'
  import SystemSelector from '@/common/components/system/SystemSelector.vue'

  const store = useStore()
  const router = useRouter()
  const i18n = useI18n()

  const profile = computed(() => {
    return store.state.user.user.id > 0 ?
      [{ label: 'common.actions.logout', onClick: logout }] :
      [{ label: 'common.actions.login', onClick: () => router.push({ name: 'login' }) }]
  })

  const canAccessAdmin = computed(() => store.getters['user/can']('admin::use_module'))
  const canAccessOverview = computed(() => store.getters['user/can']('systemtree::use_module') || store.getters['user/can']('systemtree::see_display_group_overrides'))
  const canSync = computed(() => store.getters['user/can']('systemtree::sync'))
  const user = computed(() => store.state.user.user)
  const syncJobCount = computed(() => store.state.app.getsHTTPSyncJobs?.length ?? 0)
  const needsCommit = computed(() => store.getters['app/commitPending'])
  const committingConfig = computed(() => store.getters['app/isCommittingGetsConfig'])
  const analyticsLicensed = computed(() => store.getters['license/isLicensed']('analytics'))
  const managementLicensed = computed<boolean>(() => store.getters['license/isLicensed']('management') || user.value.is_superuser)
  const appManagementLicensed = computed(() => store.getters['license/appGatewayAvailable'])
  const systems = computed(() => store.getters['app/availableSystems'])

  const canSearch = computed(() => store.getters['user/canAny'](['systemtree::use_module', 'admin::manage_system']) && managementLicensed.value)

  const navigation = computed(() => [
    {
      label: 'common.modules.overview',
      to: { name: 'treeView' },
      if: managementLicensed.value && canAccessOverview.value
    },
    {
      label: 'common.modules.analytics',
      to: { name: 'analytics' },
      permissions: 'analytics::use_module',
      if: analyticsLicensed.value
    },
    { label: 'common.modules.log', to: { name: 'log' }, permissions: 'calllog::use_module' },
    { label: 'common.modules.visual', to: { name: 'display' }, permissions: null },
    {
      label: 'common.modules.app',
      to: { name: 'appManagement' },
      permissions: 'app::use_module',
      if: appManagementLicensed.value
    },
    { label: 'common.modules.admin', to: { name: 'admin' }, if: canAccessAdmin.value },
  ])

  const syncTooltip = computed(() => needsCommit.value ? i18n.t('changelog.states.needs_commit') : i18n.t('changelog.title'))

  async function logout () {
    // Redirect to the login if no user is logged in.
    if (store.state.user.user.id === 0) {
      return await router.push({ name: 'login' })
    }

    const errorNotification: NotifyInput = {
      type: 'error',
      text: i18n.t('common.notifications.logout.error.text').toString(),
      title: i18n.t('common.notifications.logout.error.title').toString(),
    }
    try {
      const response = await fetch(`${config.backend}/logout`, {
        method: 'POST',
        credentials: 'include',
      })
      const json = await response.json()
      if (!json.ok) {
        store.commit('notifications/notify', errorNotification)
        return logError('logout action failed', json)
      }
    } catch (error) {
      store.commit('notifications/notify', errorNotification)
      return logError('logout action failed', error)
    }
    store.commit('user/logout')
    await router.push({ name: router.currentRoute.value.name === 'display' ? 'display' : 'login' })
  }
</script>

<template>
  <div
    v-if="systems.length > 1"
    class="bg-csBlue-800 relative z-30 py-1 text-white border-b border-csBlue-500 shadow-inner-md flex justify-end"
  >
    <div class="w-full max-w-7xl h-8 mx-auto px-4 sm:px-6 lg:px-8 flex justify-start">
      <div class="flex items-center text-xs font-semibold">
        <div class="mr-3">
          {{ $t('systemparam.states.current') }}
        </div>
        <SystemSelector />
      </div>
    </div>
  </div>
  <header class="bg-csBlue-600 pb-52">
    <Disclosure
      v-slot="{ open }"
      as="nav"
      class="bg-csBlue-600 border-b border-csBlue-300 border-opacity-25 lg:border-none"
    >
      <div
        class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 relative z-10"
        :class="{'opacity-50 pointer-events-none filter blur-sm': committingConfig}"
      >
        <div
          class="relative h-16 flex items-center justify-between lg:border-b lg:border-csBlue-400 lg:border-opacity-25"
        >
          <div class="px-2 flex items-center lg:px-0">
            <!-- Logo -->
            <router-link :to="{name: 'homepage'}" class="flex-shrink-0" data-testid="logo">
              <svg class="block h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.79 163.624">
                <path
                  class="fill-current text-white"
                  d="M162.79 93.493a81.81 81.81 0 11-23.72-70.12H81.82a58.43 58.43 0 1046.75 93.51H81.82a35.06 35.06 0 110-70.12h73.93a81.271 81.271 0 017 23.38h-81a11.69 11.69 0 000 23.37z"
                />
              </svg>
            </router-link>
            <!-- Navigation -->
            <div class="hidden lg:block lg:ml-10">
              <div class="flex space-x-4">
                <template v-for="item in navigation" :key="item">
                  <router-link
                    v-if="item.if !== undefined ? item.if : true"
                    v-can="item.permissions"
                    :to="item.to"
                    activeClass="bg-csBlue-500"
                    class="text-white hover:bg-csBlue-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm"
                    data-testid="nav-item"
                    :data-route-name="item.to.name"
                  >
                    {{ $t(item.label) }}
                  </router-link>
                </template>
              </div>
            </div>
          </div>
          <!-- Searchbox -->
          <div class="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
            <Search v-if="canSearch" />
          </div>

          <div class="flex lg:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="bg-csBlue-600 p-2 rounded-md inline-flex items-center justify-center text-csBlue-200 hover:text-white hover:bg-csBlue-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-csBlue-600 focus:ring-white"
            >
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>

          <div class="hidden lg:block lg:ml-4">
            <div class="flex items-center">
              <!-- Sync indicator -->
              <template v-if="managementLicensed && canSync">
                <RouterLink
                  v-if="systems.length > 0"
                  :to="{ name: 'sync' }"
                  :class="{'pointer-events-none': syncJobCount > 0}"
                  class="relative leading-none bg-csBlue-600 flex-shrink-0 rounded-full p-1 text-csBlue-200 hover:text-white focus:outline-none"
                >
                  <transition name="fade" mode="out-in">
                    <div v-if="syncJobCount > 0">
                      <svg
                        class="h-6 w-6 animate-spin"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M20 4v5h-.582M4.062 11a-8.001 8.001 0 0115.356-2m0 0H15M4 20v-5h.581m0 0a-8.003 8.003 0 0015.357-2M4.581 15H9"
                        />
                      </svg>
                      <div
                        v-if="syncJobCount > 3"
                        class="absolute bottom-0 right-0 -mb-1 -mr-1 text-2xs rounded-full bg-white w-5 h-5 font-bold flex items-center justify-center"
                      >
                        {{ syncJobCount }}
                      </div>
                    </div>
                    <div v-else v-tooltip="syncTooltip" class="relative">
                      <SwitchHorizontalIcon class="h-6 w-6" />
                      <div v-if="needsCommit" class="flex h-2 w-2 absolute bottom-0 right-0 -mb-1 -mr-1">
                        <span
                          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"
                        />
                        <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500" />
                      </div>
                    </div>
                  </transition>
                </RouterLink>
                <RouterLink
                  v-else
                  v-tooltip="$t('systemparam.states.missing')"
                  :to="{ name: 'systemParamList' }"
                  class="text-yellow-500"
                >
                  <ExclamationIcon class="h-6 w-6" />
                </RouterLink>
              </template>
              <!-- Notifications
              <button
                class="ml-4 bg-csBlue-600 flex-shrink-0 rounded-full p-1 text-csBlue-200 hover:text-white focus:outline-none">
                <BellIcon class="h-6 w-6" aria-hidden="true"/>
              </button>
              -->
              <button
                v-tooltip="$t(store.state.user.user.id > 0 ? 'common.actions.logout' : 'common.actions.login')"
                data-testid="logout"
                class="ml-4 bg-csBlue-600 rounded-full flex-shrink-0 rounded-full p-1 text-csBlue-200 hover:text-white focus:outline-none"
                @click.prevent="logout"
              >
                <LockClosedIcon class="h-6 w-6" aria-hidden="true" />
              </button>

              <!--
              <Menu as="div" class="ml-3 relative flex-shrink-0">

                <div>
                  <MenuButton
                    class="bg-csBlue-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-csBlue-600 focus:ring-white">
                    <div class="h-8 w-8 bg-csBlue-400 rounded-full flex items-center justify-center text-lg font-semibold leading-none">
                      {{ user.name.slice(0, 1).toUpperCase() }}
                    </div>
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 scale-95"
                            enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                            leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-for="item in profile" :key="item" v-slot="{ active }">
                      <a href="#" @click.prevent="item.onClick"
                         :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                        {{ $t(item.label) }}
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
                -->
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Nav -->
      <DisclosurePanel class="lg:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <template v-for="item in navigation" :key="item">
            <router-link
              v-if="item.if === undefined ? true : item.if"
              v-can="item.permissions"
              :to="item.to"
              activeClass="bg-csBlue-500"
              class="text-white hover:bg-csBlue-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base"
            >
              {{ $t(item.label) }}
            </router-link>
          </template>
        </div>
        <div class="pt-4 pb-3 border-t border-csBlue-700">
          <div class="mt-3 px-2 space-y-1">
            <a
              v-for="item in profile"
              :key="item.label"
              href="#"
              class="block rounded-md py-2 px-3 text-base text-white hover:bg-csBlue-500 hover:bg-opacity-75"
              @click.prevent="item.onClick"
            >
              {{ $t(item.label) }}
            </a>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </header>
</template>
